/** @jsx jsx */
// @ts-check
import { Box, Title } from '@bottlebooks/gatsby-theme-base/src';
import { Plural } from '@lingui/macro';
import React from 'react';
import { jsx } from 'theme-ui';

export default function ProductsPageTitle({ totalCount, count, ...rest }) {
  if (count === totalCount) {
    return (
      <Box>
        <Title {...rest}>
          <Plural
            value={totalCount}
            one={
              <React.Fragment>
                Showing you <Em>#</Em> product
              </React.Fragment>
            }
            other={
              <React.Fragment>
                Showing you <Em>#</Em> products
              </React.Fragment>
            }
          />
        </Title>
      </Box>
    );
  }

  return (
    <Box>
      <Title {...rest}>
        <Plural
          value={totalCount}
          one={
            <React.Fragment>
              Showing you <Em>{count}</Em> of <Em>#</Em> product
            </React.Fragment>
          }
          other={
            <React.Fragment>
              Showing you <Em>{count}</Em> of <Em>#</Em> products
            </React.Fragment>
          }
        />
      </Title>
    </Box>
  );
}

export function Em({ children, ...rest }) {
  return (
    <em sx={{ color: 'primary', fontStyle: 'normal' }} {...rest}>
      {children}
    </em>
  );
}
